const Posts = [
  {
    title: 'Teste',
    text: 'Em breve',
    image:
      'https://cdn-images-1.medium.com/max/400/1*ufP5nfg3eLEkIrqKB2i8mw.jpeg',
    url: 'https://jordhan.dev',
    date: '25 de janeiro',
    wtime: '2 min',
  },
];

export default Posts;
